import ReactGA from "react-ga"

export const trackCustomEvent = (trackinStuff) => {
  ReactGA.event(trackinStuff)
  // {
  //   category,
  //   action,
  //   value
  // }
}
